<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('graduation_requirements').toUpper() }}</h5>
        <b-card class="soft mb-5">
            <b-row>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('graduation_credit') }}</label>
                        <div>
                            {{ studentProgram.graduation_credit }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('graduation_ects_credit') }}</label>
                        <div>
                            {{ studentProgram.graduation_ects_credit }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('graduation_dep') }}</label>
                        <div>
                            {{ studentProgram.graduation_dep }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('dep_credit') }}</label>
                        <div>
                            {{ studentProgram.dep_credit }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('dep_ects_credit') }}</label>
                        <div>
                            {{ studentProgram.dep_ects_credit }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('graduation_non_dep') }}</label>
                        <div>
                            {{ studentProgram.graduation_non_dep }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('non_dep_credit') }}</label>
                        <div>
                            {{ studentProgram.non_dep_credit }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('non_dep_ects_credit') }}</label>
                        <div>
                            {{ studentProgram.non_dep_ects_credit }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('graduation_general') }}</label>
                        <div>
                            {{ studentProgram.graduation_general }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('general_credit') }}</label>
                        <div>
                            {{ studentProgram.general_credit }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="label-as-input-div">
                        <label>{{ $t('general_ects_credit') }}</label>
                        <div>
                            {{ studentProgram.general_ects_credit }}
                        </div>
                    </div>
                </b-col>

            </b-row>
        </b-card>

        <b-button variant="primary" @click="updateFormShow"
                  v-if="checkPermission('studentprogram_update') && editMode && studentProgram.can_graduation_requirement_edit == true">
            {{ $t('edit') }}
        </b-button>
        <b-button variant="primary" @click="recalculateGpa" v-if="editMode && studentProgram.can_graduation_requirement_edit == false">
            {{$t('recalculate')}}
        </b-button>

        <CommonModal ref="formModal" size="xxl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="clearFormData()"
                     v-if="checkPermission('studentprogram_update') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Component
    import CommonModal from '@/components/elements/CommonModal';
    import { EventBus } from '@/main'

    // Update Form
    import UpdateForm from './UpdateForm';

    export default {
        components: {
            CommonModal,
            UpdateForm
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formProcess: null
            }
        },
        methods: {
            // Clear
            clearFormData() {
                this.formProcess = null
            },

            // Update
            updateFormShow() {
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            },
            recalculateGpa(){
                EventBus.$emit('recalculateGpa');
            }
        }
    }
</script>

