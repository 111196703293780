<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="border rounded p-4 mb-3">
                <b-row>
                    <b-col sm="4">
                        <ValidationProvider name="graduation_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_credit')">
                                <b-form-input type="number" v-model="formData.graduation_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduation_ects_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_ects_credit')">
                                <b-form-input type="number" v-model="formData.graduation_ects_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduation_dep"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_dep')">
                                <b-form-input type="number" v-model="formData.graduation_dep"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="dep_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('dep_credit')">
                                <b-form-input type="number" v-model="formData.dep_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="dep_ects_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('dep_ects_credit')">
                                <b-form-input type="number" v-model="formData.dep_ects_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduation_non_dep"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_non_dep')">
                                <b-form-input type="number" v-model="formData.graduation_non_dep"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="non_dep_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('non_dep_credit')">
                                <b-form-input type="number" v-model="formData.non_dep_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="non_dep_ects_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('non_dep_ects_credit')">
                                <b-form-input type="number" v-model="formData.non_dep_ects_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="graduation_general"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_general')">
                                <b-form-input type="number" v-model="formData.graduation_general"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="general_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('general_credit')">
                                <b-form-input type="number" v-model="formData.general_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="general_ects_credit"
                                            v-slot="{ errors }">
                            <b-form-group :label="$t('general_ects_credit')">
                                <b-form-input type="number" v-model="formData.general_ects_credit"
                                              :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </div>
            <div class="d-flex justify-content-center">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components

// Services
import StudentProgramService from '@/services/StudentProgramService'

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    name: "StudentsUniversityInformationUpdate",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            formId: null,
            formData: {},
            formLoading: false,
            advisorObject: {}
        }
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        }
    },
    methods: {
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                StudentProgramService.updateGraduateRequirementInfo(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    })
            }
        }
    },
    created() {
        this.formId = this.studentProgram.id;
        this.formData = {

            graduation_ects_credit: this.studentProgram.graduation_ects_credit,
            graduation_credit: this.studentProgram.graduation_credit,
            graduation_dep: this.studentProgram.graduation_dep,
            dep_credit: this.studentProgram.dep_credit,
            dep_ects_credit: this.studentProgram.dep_ects_credit,
            graduation_non_dep: this.studentProgram.graduation_non_dep,
            non_dep_credit: this.studentProgram.non_dep_credit,
            non_dep_ects_credit: this.studentProgram.non_dep_ects_credit,
            graduation_general: this.studentProgram.graduation_general,
            general_credit: this.studentProgram.general_credit,
            general_ects_credit: this.studentProgram.general_ects_credit,
        }

    }
}
</script>

